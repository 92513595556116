import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>I'm a Full Stack web developer working from home</h3>
                            <div className="separator" />
                            <p>Web Developer with 5+ years in developing and designing websites, user interfaces, 
debugging and deploying web-based applications for enterprise use. Proven ability in 
developing web functionalities that improve data retrieval and workflow efficiencies. 
Experience with React.js, Vue.js, Angular.js, Node.js, REST API's, Express, and Redux. My 
recent work experience: developed user interface components and implemented Redux for 
global state management awaiting and dispatching multiple API requests for a tailored user 
experience, on an enterprise use e-commerce web application. Responsible for data 
retrieval and maintainability across an end to end application for mobile and web use. My 
specialty: e-commerce based enterprise use web apps, professional design and user 
experience with focused asynchronous functionalities and API implementation.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://www.github.com/supportfnldst')}/>
                                {/* <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://www.twitter.com')} />
                                <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com')} /> */}
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/brandon-anthony-galli/')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="Web Development" value={95} delay={1100} />
                                <Progress name="Javascript" value={100} delay={1100} />
                                <Progress name="Typescript" value={100} delay={1100} />
                                <Progress name="React" value={95} delay={1100} />
                                <Progress name="Vue" value={95} delay={1100} />
                                <Progress name="Angular" value={90} delay={1100} />
                                <Progress name="CSS3" value={100} delay={1100} />
                                <Progress name="HTML5" value={100} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero